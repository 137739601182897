import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, { label: "提示文字" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _ctx._placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx._placeholder) = $event)),
          size: "default",
          placeholder: "请设置提示语"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, { label: "是否多选" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_switch, {
          modelValue: _ctx._value.multiple,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx._value.multiple) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}